import {styled} from "@mui/material/styles";

type DivProps = {
    centered?: boolean,
    spacing?: number,
    backgroundColor?: string,
    flexDirectionColumn?: boolean
}

export const Div_Content = styled('div')<DivProps>(({ theme, centered, spacing, backgroundColor, flexDirectionColumn }) => ({
    backgroundColor: backgroundColor ? backgroundColor : 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: centered ? 'center' : 'initial',
    flexDirection: flexDirectionColumn ? 'column' : 'row',
    padding: spacing ? theme.spacing(spacing) : 'initial',
}))